import React, { useEffect, useState } from "react";
import SearchAndFilter from "../../CommomComponents/SearchAndFilter/SearchAndFilter";
import SearchSection from "../../CommomComponents/SearchAndFilter/SearchSection";
import { useEvent } from "../../contexts/EventContext";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import { useNavigate } from "react-router-dom";
import "./Chats.css";
import { Chat } from "./Chat";
import { Icon } from "@iconify/react/dist/iconify.js";
import chatApiService, { getListOfAttendees } from "../../crud/chat.crud";
import { Attendee } from "./Attendee";
import { spinnerStyle } from "../../config/app";
import { ClipLoader } from "react-spinners";
import { SwipeableDrawer } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import { useMessageContext } from "../../contexts/MessageContext";
import SideNavbar from "../SideNavbar/SideNavbar";
import _ from "lodash";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import {
	getComplementaryColor,
	getModuleDescriptionByName,
	getModuleNameByType
} from "../../reusable/common";
import { useModule } from "../../contexts/ModuleContext";
import Footer from "../Footer/Footer";

const Chats: React.FC = () => {
	const navigate = useNavigate();
	const { modulesDetails } = useModule();
	const { messages, setMessages } = useMessageContext();
	const [searchTerm, setSearchTerm] = useState("");
	const [allContactsSearchTerm, setAllContactsSearchTerm] = useState("");
	const { eventDetails } = useEvent();
	const [chats, setChats] = useState([]);
	const [filteredChats, setFilteredChats] = useState([]);
	const [showAllContacts, setShowAllContacts] = useState(false);
	const [allContacts, setAllContacts] = useState([]);
	const [filteredAllContacts, setFilteredAllContacts] = useState([]);
	const [isChatLoading, setIsChatLoading] = useState(false);
	const [areAttendeesLoading, setAreAttendeesLoading] = useState(false);
	const currentUserAttendeeId = localStorage.getItem("attendeeId");
	const [areChatsLoaded, setAreChatsLoaded] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	const moduleHeading = getModuleNameByType(modulesDetails, 4);

	const goBack = () => {
		return navigate("/");
	};

	const processChats = (chatList: any) => {
		return chatList
			?.map(chat => {
				return {
					name: chat?.sender_name,
					lastMessage: chat?.last_msg,
					lastMessageAt: chat?.last_msg_at,
					profileImage: chat?.sender_image_url,
					unreadCount: chat?.unread_count,
					roomId: chat?.room_id,
					attendeeId: chat?.sender_id,
					designation: chat?.designation
				};
			})
			.sort(chat => chat.lastMessageAt);
	};

	const getChatList = async () => {
		if (eventDetails?.event_id) {
			setIsChatLoading(true);
			const response = await chatApiService.listChats(eventDetails?.event_id);
			const processedChats = processChats(response);
			setChats(processedChats);
			setFilteredChats(processedChats);
			setIsChatLoading(false);
		}
	};

	const storeFp = (chat: any) => {
		localStorage.setItem("fpDetails", JSON.stringify(chat ?? {}));
	};

	const findAttendee = (attendeeList: any, attendeeId: string) => {
		return attendeeList?.find(contact => contact?.attendee_id === attendeeId);
	};

	const onContactClick = async (attendeeId: string) => {
		const filteredChat = chats?.filter(chat => chat?.attendeeId === attendeeId);
		if (filteredChat?.length) {
			onChatClick(filteredChat?.[0]);
		} else {
			const fp = findAttendee(allContacts, attendeeId);
			storeFp({
				name: fp?.att_name,
				profileImage: fp?.image_url,
				designation: fp?.designation,
				attendeeId: fp?.attendee_id
			});
			navigate(`/chats/${attendeeId}?isNew=true`);
		}
	};

	const onChatClick = async (chat: any) => {
		storeFp(chat);
		navigate(`/chats/${chat?.roomId}`);
	};

	const updateChatList = () => {
		if (!messages?.length) return;

		const currentChatRoomIds = new Set(chats?.map(c => c?.roomId));
		const currentMessageRoomIds = new Set(messages?.map(c => c?.roomId));

		const hasNewRoom = [...currentMessageRoomIds].some(id => !currentChatRoomIds.has(id));

		if (hasNewRoom) {
			getChatList();
			setMessages([]);
			return;
		}

		const updatedChats = [...chats];
		messages.forEach(message => {
			const foundIndex = updatedChats.findIndex(chat => chat.roomId === message.roomId);
			if (foundIndex !== -1) {
				const updatedChat = {
					...updatedChats[foundIndex],
					lastMessage: message?.message,
					lastMessageAt: new Date(),
					unreadCount: message?.unreadCount
				};
				updatedChats.splice(foundIndex, 1);
				updatedChats.unshift(updatedChat);
			}
		});

		setChats(updatedChats);
		setFilteredChats(updatedChats);
		setMessages([]);
	};

	useEffect(() => {
		if (messages?.length) {
			setTimeout(() => updateChatList(), 0);
		}
	}, [messages]);

	useEffect(() => {
		localStorage.removeItem("fpDetails");
		getChatList().then(() => {
			setAreChatsLoaded(true);
		});
	}, []);

	const getAttendees = async ({
		page = 1,
		searchTerm = ""
	}: {
		page?: number;
		searchTerm?: string;
	}) => {
		setAreAttendeesLoading(true);
		const limit = 20;
		const offset = (page - 1) * limit;

		const response = await getListOfAttendees({
			eventId: eventDetails?.event_id,
			limit,
			offset,
			searchTerm
		});
		setCurrentPage(page);

		if (page === 1) {
			setAllContacts(response?.data?.attendees);
			setFilteredAllContacts(response?.data?.attendees);
		} else {
			setAllContacts(prev => [...prev, ...response?.data?.attendees]);
			setFilteredAllContacts(prev => [...prev, ...response?.data?.attendees]);
		}

		setAreAttendeesLoading(false);
		return response?.data?.attendees?.length < limit;
	};

	useEffect(() => {
		setAreAttendeesLoading(true);
		const debouncedGetAttendees = _.debounce(() => {
			setHasMore(true);
			setCurrentPage(1);
			if (showAllContacts && allContactsSearchTerm !== "") {
				setCurrentPage(1);
				setHasMore(true);
				getAttendees({ page: 1, searchTerm: allContactsSearchTerm });
			} else if (showAllContacts) {
				getAttendees({ page: 1 });
			}
		}, 1000);
		debouncedGetAttendees();
		return () => {
			debouncedGetAttendees.cancel();
		};
	}, [allContactsSearchTerm]);

	useEffect(() => {
		if (showAllContacts) {
			getAttendees({});
		} else {
			setAllContacts([]);
			setFilteredAllContacts([]);
			setHasMore(true);
			setCurrentPage(1);
		}
	}, [showAllContacts]);

	useEffect(() => {
		if (searchTerm) {
			const filtered = chats?.filter(chat =>
				chat?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
			);
			setFilteredChats(filtered);
		} else {
			setFilteredChats(chats);
		}
	}, [searchTerm]);

	useEffect(() => {
		if (allContactsSearchTerm) {
			const filtered = allContacts?.filter(contact =>
				contact?.att_name?.toLowerCase()?.includes(allContactsSearchTerm?.toLowerCase())
			);
			setFilteredAllContacts(filtered);
		} else {
			setFilteredAllContacts(allContacts);
		}
	}, [allContactsSearchTerm]);

	return (
		<>
			<div className="chats-wrapper">
				<div className="side-nav-bar-wrapper">
					<SideNavbar />
				</div>
				<div className="chats-inner-wrapper">
					<ModuleHeading goBack={goBack} moduleHeading={moduleHeading} />
					<ModuleDescription
						description={getModuleDescriptionByName(modulesDetails, moduleHeading)}
					/>

					<div className="new-chat-search-wrapper">
						{!showAllContacts && (
							<div
								className="desktop-new-chat-button"
								onClick={() => setShowAllContacts(true)}
							>
								New Chat{" "}
								<Icon
									icon="material-symbols-light:chat-add-on-outline"
									height={24}
									width={24}
								/>
							</div>
						)}
						{chats?.length > 10 && (
							<div className="search-bar_network-desktop">
								<div className="search-bars speaker-search no-margin">
									<div className="search-input-wrapper">
										<SearchSection
											eventId={eventDetails?.event_id}
											searchTerm={searchTerm}
											setSearchTerm={setSearchTerm}
											showFilter={false}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					{!filteredChats?.length && !isChatLoading && (
						<div
							style={{
								textAlign: "center",
								marginTop: "30px",
								color: "var(--primary-color)"
							}}
						>
							No chats found
						</div>
					)}
					{!!filteredChats?.length && (
						<div className="all-chats-wrapper">
							{filteredChats?.map(chat => (
								<Chat
									chat={chat}
									image={chat?.profileImage}
									name={chat?.name}
									latestMessage={chat?.lastMessage}
									unreadCount={chat?.unreadCount}
									lastMessageAt={chat?.lastMessageAt}
									onClick={onChatClick}
								/>
							))}
						</div>
					)}
					{showAllContacts && (
						<SwipeableDrawer
							className="chats-swipeable-drawer-wrapper"
							anchor="bottom"
							open={showAllContacts}
							onClose={() => setShowAllContacts(false)}
						>
							<div
								style={{
									position: "sticky",
									top: 0,
									zIndex: 10
								}}
							>
								<div className="chat-search-wrapper">
									<div className="drawer-top-line" />

									<SearchSection
										eventId={eventDetails?.event_id}
										searchTerm={allContactsSearchTerm}
										setSearchTerm={setAllContactsSearchTerm}
										searchPlaceHolder={"Search in all contacts"}
									/>
								</div>
							</div>
							<InfiniteScroll
								pageStart={1}
								loadMore={async () => {
									if (!allContacts?.length) return;
									const noMoreItems = await getAttendees({
										page: currentPage + 1,
										searchTerm: allContactsSearchTerm
									});
									if (noMoreItems) {
										setHasMore(false);
									}
								}}
								hasMore={hasMore && !areAttendeesLoading}
								useWindow={false}
								className="all-contacts-list"
							>
								{filteredAllContacts?.map(contact => (
									<Attendee
										key={contact?.attendee_id}
										id={contact?.attendee_id}
										image={contact?.image_url}
										name={contact?.att_name}
										designation={contact?.designation}
										organisation={contact?.organisation}
										onClick={onContactClick}
									/>
								))}
								{areAttendeesLoading && (
									<div style={{ textAlign: "center", padding: "10px" }} key={0}>
										<ClipLoader loading={true} size={30} />
									</div>
								)}
							</InfiniteScroll>

							{!filteredAllContacts?.length && !areAttendeesLoading && (
								<div
									style={{
										textAlign: "center",
										marginTop: "40px",
										color: "var(--primary-color)"
									}}
								>
									No contact found
								</div>
							)}
						</SwipeableDrawer>
					)}
					{isChatLoading && (
						<div style={spinnerStyle}>
							<ClipLoader
								loading={isChatLoading}
								size={50}
								aria-label="Loading Spinner"
							/>
						</div>
					)}
					{!showAllContacts && (
						<div className="new-chat-button" onClick={() => setShowAllContacts(true)}>
							<Icon
								icon="material-symbols-light:chat-add-on-outline"
								height={24}
								width={24}
								style={{
									color: getComplementaryColor(eventDetails?.att_app_pri_col)
								}}
							/>
						</div>
					)}
				</div>
				<div className="hide_footer">
					<Footer />
				</div>
			</div>
		</>
	);
};

export default Chats;
