import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment-timezone";
import { unsubscribeAllServices } from "../utils/subscriptions/subscriptions";
import { currentEnv } from "../config/env";

DOMPurify.addHook("afterSanitizeAttributes", (node: any) => {
	// Check if the node is an <a> (anchor) tag
	if (node.tagName === "A") {
		// Always set target="_blank" for all links
		node.setAttribute("target", "_blank");

		// Ensure rel="noopener noreferrer" is set for security
		node.setAttribute("rel", "noopener noreferrer");
	}
});

export const logout = async () => {
	try {
		if (currentEnv === "custom") {
			localStorage.clear();
			for (let key in localStorage) {
				if (key.includes("CognitoIdentityServiceProvider")) {
					localStorage.removeItem(key);
				}
			}
		} else {
			let cookies = Cookies.get();
			const domains = [
				"localhost",
				".konfhub.com",
				".futuresustainabilityforum.com",
				"webapp.futuresustainabilityforum.com",
				"fsf.codeops.tech"
			]; // Specify the domains
			const path = "/";
			Object.keys(cookies).forEach(key => {
				if (key.includes("CognitoIdentityServiceProvider")) {
					domains.forEach(domain => {
						Cookies.remove(key, { path, domain });
						localStorage.removeItem(key);
						localStorage.clear();
					});
				} else {
					domains.forEach(domain => {
						if (
							domain === ".futuresustainabilityforum.com" ||
							domain === ".fsf.codeops.tech"
						) {
							Cookies.remove(key, { path, domain });
							localStorage.removeItem(key);
							localStorage.clear();
						}
					});
				}
			});
		}
		await Auth.signOut();
		localStorage.clear();
		unsubscribeAllServices();
	} catch (err) {
		console.log(err);
	}
};

export const useScreenWidth = () => {
	const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const mediaQuery = window.matchMedia("(max-width: 769px)");
		const handleScreenChange = (event: MediaQueryListEvent) => {
			setIsScreenSmall(event.matches);
		};

		// Set initial state based on media query
		setIsScreenSmall(mediaQuery.matches);

		// Add listener to handle changes
		mediaQuery.addEventListener("change", handleScreenChange);

		// Cleanup listener on component unmount
		return () => {
			mediaQuery.removeEventListener("change", handleScreenChange);
		};
	}, []);

	return isScreenSmall;
};

export const stripHtmlTags = (html: string): string => {
	return html?.replace(/<\/?[^>]+(>|$)/g, "");
};

export function getComplementaryColor(hexColor: string): string {
	// Convert hex color to RGB
	function hexToRgb(hex: string): { r: number; g: number; b: number } {
		let r = 0,
			g = 0,
			b = 0;
		if (hex?.length === 4) {
			r = parseInt(hex[1] + hex[1], 16);
			g = parseInt(hex[2] + hex[2], 16);
			b = parseInt(hex[3] + hex[3], 16);
		} else if (hex?.length === 7) {
			r = parseInt(hex[1] + hex[2], 16);
			g = parseInt(hex[3] + hex[4], 16);
			b = parseInt(hex[5] + hex[6], 16);
		}
		return { r, g, b };
	}

	// Calculate the relative luminance of an RGB color
	function getLuminance({ r, g, b }: { r: number; g: number; b: number }): number {
		// Normalize RGB values to a 0-1 range
		const [nr, ng, nb] = [r, g, b].map(v => {
			v /= 255;
			return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
		});
		// Calculate luminance
		return 0.2126 * nr + 0.7152 * ng + 0.0722 * nb;
	}

	// Choose white or black based on luminance
	function getContrastColor(luminance: number): string {
		// Threshold for contrast is 0.5
		return luminance > 0.5 ? "#000000" : "#FFFFFF";
	}

	const rgb = hexToRgb(hexColor);
	const luminance = getLuminance(rgb);
	return getContrastColor(luminance);
}

export const formatTimestamp = (startTimestamp, endTimestamp, eventTimeZone) => {
	if (!startTimestamp || !endTimestamp || !eventTimeZone) {
		console.error("Invalid input provided");
		return "Invalid input";
	}
	const startDateInUTC = new Date(`${startTimestamp}Z`);
	const endDateInUTC = new Date(`${endTimestamp}Z`);
	const startTimeInEventTZ = startDateInUTC.toLocaleTimeString("en-US", {
		timeZone: eventTimeZone,
		hour: "2-digit",
		minute: "2-digit"
	});

	const endTimeInEventTZ = endDateInUTC.toLocaleTimeString("en-US", {
		timeZone: eventTimeZone,
		hour: "2-digit",
		minute: "2-digit"
	});

	let eventTimeZoneAbbreviation = moment.tz(eventTimeZone).zoneAbbr();

	const timeZoneAbbreviations = {
		"Asia/Dubai": "GST",
		"Asia/Kolkata": "IST",
		"Asia/Calcutta": "IST",
		UTC: "UTC"
	};

	if (timeZoneAbbreviations[eventTimeZone]) {
		eventTimeZoneAbbreviation = timeZoneAbbreviations[eventTimeZone];
	}
	const availableTimeZones = moment.tz.names();

	if (availableTimeZones.includes(eventTimeZone)) {
		return `${startTimeInEventTZ} - ${endTimeInEventTZ} (${eventTimeZoneAbbreviation})`;
	} else {
		return `${startTimeInEventTZ} - ${endTimeInEventTZ} (Invalid Timezone)`;
	}
};

// Returns date and time from utc to IST
export const getISTDateNTime = (dateTime: any, timezone: any) => {
	let convertedDateTime;
	convertedDateTime = moment.utc(dateTime).tz(timezone);
	return moment(convertedDateTime).format("YYYY-MM-DD HH:mm:ss");
};

export const convertToDateObj = (value: any) => {
	if (value) return moment(value).toDate();
	return null;
};

// Returns date and time from IST to utc
export const getUTCDateNTime = (dateTime: any, timezone: string) => {
	const tempDateTime = moment.tz(dateTime, timezone).format();

	let tempData;
	tempData = new Date(tempDateTime).toISOString();
	tempData = tempData?.toString();
	tempData = tempData?.slice(0, tempData?.length - 5);
	tempData = tempData?.replaceAll("T", " ");

	return tempData;
};

export const convertStartEndDateToIST = (
	start_date: string,
	start_time: string,
	end_date: string,
	end_time: string,
	time_zone: string
) => {
	/*UTC to IST conversion */
	let ISTStartDateAndStartTime; /* variable to store IST start date and start time.*/

	let ISTEndDateAndEndTime; /* variable to store IST end date and end time. */

	/*Calling getISTDateNTime function and passing API received start_date and start_time to it as arguments. */
	ISTStartDateAndStartTime = getISTDateNTime(start_date + " " + start_time, time_zone);

	/*Calling getISTDateNTime function and passing API received end_date and end_time to it as arguments. */
	ISTEndDateAndEndTime = getISTDateNTime(end_date + " " + end_time, time_zone);

	/*setting API received start_date to IST start date. */
	start_date = ISTStartDateAndStartTime.split(" ")[0];

	/*setting API received end_date to IST end date. */
	end_date = ISTEndDateAndEndTime.split(" ")[0];

	/*setting API received start_time to IST start time.  */
	start_time = ISTStartDateAndStartTime.split(" ")[1];

	/*setting API received end_time to IST end time.  */
	end_time = ISTEndDateAndEndTime.split(" ")[1];

	return { start_date, start_time, end_date, end_time };
};
// export const formatTimestamp = (startTimestamp: string, endTimestamp: string, eventDetails: any) => {
// 	let options : any = {
// 		hour: "2-digit",
// 		minute: "2-digit",
// 		hour12: true,
// 		timeZone: eventDetails?.time_zone
// 	};
// 	let startDate = new Date(startTimestamp);
// 	let endDate = new Date(endTimestamp);
// 	let startTime = startDate.toLocaleTimeString("en-US", options);
// 	let endTime = endDate.toLocaleTimeString("en-US", options);

// 	const timeZoneAbbreviation =
// 		timeZoneAbbreviations[eventDetails?.time_zone] || eventDetails?.time_zone; // Fallback to the full name if not found
// 	return `${startTime} - ${endTime} (${timeZoneAbbreviation})`; // Return formatted time with abbreviation
// }

/**** Function to return only the differences of initial values & values object *****/
export const returnObjDiff = (initialValues, values) => {
	let diffObj = {};
	Object.keys(values).map(key => {
		if (!initialValues.hasOwnProperty(key)) {
			diffObj = { ...diffObj, [key]: values[key] };
		} else if (initialValues[key] !== values[key]) {
			diffObj = { ...diffObj, [key]: values[key] };
		}
	});

	return diffObj;
};

export const addEmptyStringForNullValues = data => {
	let updatedValues = {};
	Object.keys(data).map(key => {
		if (data[key] === null) updatedValues = { ...updatedValues, [key]: "" };
		else updatedValues = { ...updatedValues, [key]: data[key] };
	});
	return updatedValues;
};

export const addNullForEmptyString = data => {
	let updatedValues = {};
	Object.keys(data).map(key => {
		if (data[key] === "") updatedValues = { ...updatedValues, [key]: null };
		else updatedValues = { ...updatedValues, [key]: data[key] };
	});
	return updatedValues;
};

export const useClipboard = () => {
	const [copied, setCopied] = useState(false);

	const copyToClipboard = async (text: string) => {
		try {
			await navigator.clipboard.writeText(text);
			setCopied(true);
			setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
		} catch (err) {
			console.error("Failed to copy text: ", err);
		}
	};

	return { copied, copyToClipboard };
};

export const firstLetterFromString = (val: string) => {
	return val?.substring(0, 1)?.toUpperCase();
};

export const domPurify = (content: string) => {
	return DOMPurify.sanitize(content, {
		ALLOWED_ATTR: ["href", "rel", "target"] // Ensure necessary attributes are allowed
	});
};

export const getModuleDescriptionByName = (modules, moduleName) => {
	const modulesArray = Object.values(modules);
	const module = modulesArray.find(mod => mod?.module_name === moduleName);
	return module ? module?.description : "";
};

export const getModuleNameByType = (data, type) => {
	const module = Object.values(data).find(module => module.module_type === type);
	return module ? module.module_name : null;
};

export const truncateText = (text, limit = 30) => {
	if (!text) return "";
	return text?.length > limit ? `${text.slice(0, limit)}...` : text;
};

export const ROLES = {
	ATTENDEE: 1,
	ORGANISER: 2,
	EXHIBITOR: 3,
  };