import { useState, useEffect } from "react";
import { filterNetwork } from "../../../crud/network.crud";
import { useEvent } from "../../../contexts/EventContext";

export const useNetworkFilter = (initialFilter: string) => {
    const [selectedMenu, setSelectedMenu] = useState<string>("Attendee Category");
    const [selectedTag, setSelectedTag] = useState<string>(initialFilter);
    const [filterOptions, setFilterOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { eventDetails } = useEvent();

    useEffect(() => {
        fetchFilters();
    }, []);



    const fetchFilters = async () => {
        setLoading(true);
        try {
            const response = await filterNetwork(eventDetails?.event_id);
            setFilterOptions(response.data.filter_options || []);
        } catch (error) {
            console.error("Error fetching filter options:", error?.response || error);
        } finally {
            setLoading(false);
        }
    };

    const filterData = [
        {
            id: "attendee-category",
            name: "Attendee Category",
            tags: filterOptions.map((option, index) => ({
                id: (index + 1).toString(),
                name: option,
            })),
        },
    ];

    const clearFilters = (setFilterData: any) => {
        setSelectedTag("");
        setFilterData("")
    };

    const handleApplyFilters = (setFilterData: any, onClose: () => void) => {
        setFilterData(selectedTag);
        onClose();
    };

    return {
        selectedMenu,
        setSelectedMenu,
        selectedTag,
        setSelectedTag,
        filterData,
        loading,
        clearFilters,
        handleApplyFilters
    };
};
