// The code here is similar to the mobile component but without the SwipeableDrawer wrapper.
import React from "react";
import {
    Box,
    List,
    ListItemButton,
    ListItemText,
    Checkbox,
    Button,
    Typography,
    FormControlLabel,
} from "@mui/material";
import { ClipLoader } from "react-spinners";
import "./NetworkFilter.css";
import { useNetworkFilter } from "./useNetworkFilter";

const NetworkFilterDesktop = ({
    onClose,
    selectedFilter,
    setFilterData,

}) => {
    const {
        selectedMenu,
        setSelectedMenu,
        selectedTag,
        setSelectedTag,
        filterData,
        loading,
        clearFilters,
        handleApplyFilters
    } = useNetworkFilter(selectedFilter);



    return (
        <div className="filter-drawer-section-network">
            <Typography variant="h6" className="headText_filter">
                Filters
            </Typography>
            {loading ? (
                <Box className="loader-container">
                    <ClipLoader color="var(--secondary-color)" size={40} />
                </Box>
            ) : (
                <Box className="filter-container">
                    <Box className="filter-sidebar">
                        <List>
                            {filterData.map((filterItem) => (
                                <ListItemButton
                                    key={filterItem.id}
                                    onClick={() => setSelectedMenu(filterItem.name)}
                                    selected={selectedMenu === filterItem.name}
                                    style={{
                                        backgroundColor:
                                            selectedMenu === filterItem.name
                                                ? "var(--secondary-color) !important"
                                                : "",
                                        color: 'var(--comp-secondary-color) !important',
                                    }}
                                >
                                    <ListItemText primary={filterItem.name} />
                                </ListItemButton>
                            ))}
                        </List>
                    </Box>
                    <Box className="filter-content">
                        {filterData.map(
                            (filterItem) =>
                                selectedMenu === filterItem.name && (
                                    <List key={filterItem.id}>
                                        {filterItem.tags.map((tag) => (
                                            <ListItemButton key={tag.id}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{
                                                                color: selectedTag === tag.name ? 'var(--secondary-color)' : '',
                                                            }}
                                                            checked={selectedTag === tag.name}
                                                            onChange={() => setSelectedTag(tag.name)}
                                                        />
                                                    }
                                                    label={tag.name}
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                )
                        )}
                    </Box>
                </Box>
            )}

            <Box className="filter-actions">
                <Button
                    variant="outlined"
                    onClick={() => {
                        clearFilters(setFilterData);
                        onClose();
                    }}
                    disabled={!selectedTag}
                    className="btn_agenda clear-btn text-capitalize"
                >
                    Clear all
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleApplyFilters(setFilterData, onClose)}
                    disabled={!selectedTag}
                    className="applybtn_agenda btn_agenda text-capitalize"
                >
                    Apply
                </Button>
            </Box>


        </div>
    );
};

export default NetworkFilterDesktop;
