import React, { useEffect, useState } from "react";
import { Drawer, Typography, IconButton, Avatar, SwipeableDrawer } from "@mui/material";
import "./EventDrawer.css";
import { timeZoneAbbreviations } from "../../config/app";
import { Icon } from "@iconify/react/dist/iconify.js";
import moment from "moment";
import "moment-timezone";
import CustomDrawer from "../../CommomComponents/CustomDrawer/CustomDrawer";
import SpeakerDetailsComp from "../SpeakerPage/SpeakerDetailsComp/SpeakerDetailsComp";
import { formatTimestamp } from "../../reusable/common";
import { getSessionDetails } from "../../crud/agenda.crud";
import { useEvent } from "../../contexts/EventContext";
import CustomLoader from "../../CommomComponents/CustomLoader/CustomLoader";
import { useNavigate } from "react-router-dom";

interface Speaker {
	name: string;
	avatar: string;
	dec: string;
}

interface EventDrawerProps {
	open: boolean;
	onClose: () => void;
	onOpen: () => void;
	modalData: {
		session_time: string;
		session_title: string;
		session_description: string;
		session_speakers: Speaker[];
		session_tags: string[];
		eventsData: [];
	};
	speakers?: any[];
	setOpenDrawer?: any;
	openDrawer?: boolean | any;
}

const EventDrawer: React.FC<EventDrawerProps> = ({
	modalData,
	setModalData,
	open,
	onOpen,
	onClose,
	speakers,
	eventsData,
	data,
	setOpenDrawer,
	openDrawer
}) => {
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search); // Parse the search string
	const sessionId = searchParams.get("sessionId");
	const speakerId = searchParams.get("speakerId");
	const [loading, setLoading] = useState(false);
	const [speakerDetails, setSpeakerDetails] = useState<any>(null);
	const [modalOpenDesktop, setModalOpenDesktop] = useState<boolean>(false);
	const [isSessionDrawerOpen, setIsSessionDrawerOpen] = useState(false);
	const [individualSpeakerSession, setIndividualSpeakerSession] = useState([]);

	const fullDetails = { ...speakerDetails, sessions: individualSpeakerSession };
	const { eventDetails } = useEvent();
	const getSessionDetailsOfIndividual = async (eventId: string, speaker: any) => {
		setLoading(true);
		try {
			const res = await getSessionDetails(eventId, speaker?.speaker_id);
			setIndividualSpeakerSession(res.data);
			if (res.data) {
				setSpeakerDetails(() => {
					return {
						...speaker,
						sessions: res?.data?.map((each: any) => {
							let _session_speakers = [];
							_session_speakers = each?.session_speakers?.filter((data: object) => {
								const _speaker = speakers?.filter(
									(each: any) => each?.speaker_id === data
								)?.[0];
								data = { ..._speaker };
								return data;
							});
							each.session_speakers = _session_speakers;
							return each;
						})
					};
				});
			}
		} catch (error) {
			console.log("error", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<SwipeableDrawer
			anchor="bottom"
			open={open}
			onClose={onClose}
			onOpen={onOpen}
			className="eventDrawer hide-in-desktop"
		>
			<div className="drawer-top-line" aria-hidden="true" onClick={onClose}></div>
			<div className="d-flex justify-content-end mb-1 me-4">
				<Icon
					role="button"
					icon={`material-symbols-light:close`}
					width={"24px"}
					height={"24px"}
					onClick={() => {
						onClose();
					}}
				/>
			</div>
			<div className="main-eventDrawer">
				<div className="timesection-event">
					<h4 className="event-time">
						{formatTimestamp(
							modalData?.start_timestamp,
							modalData?.end_timestamp,
							eventsData?.time_zone
						)}
					</h4>
				</div>
				{/* 
        <div className="Plenary-text">
          <IconButton aria-label="location-eventdrawer">
            <img src="assets/icons/Address.svg" alt="bookmark" className="icons-size-loc" />
          </IconButton>
          <h5>{modalData.session_title}</h5>
        </div> */}
				<h4 className="event-drawer-text">{modalData?.session_title}</h4>
				{modalData?.session_location && (
					<div className="d-flex align-items-center mt-2" style={{ marginLeft: "0px" }}>
						<Icon
							icon={`fluent:location-16-regular`}
							width={"18px"}
							height={"18px"}
							weight={20}
							className="primary-color-icons"
						/>
						<p className="venue-address" style={{ marginLeft: "7px" }}>
							{modalData?.session_location}
						</p>
					</div>
				)}
				<p
					className="text-edrawer"
					dangerouslySetInnerHTML={{
						__html: (() => {
							const stripHtml = (html: string) => html.replace(/<[^>]*>/g, ""); // Remove HTML tags

							const plainText = stripHtml(modalData?.session_description || "");
							return plainText.length > 150
								? `${plainText.slice(0, 150)}...`
								: plainText;
						})()
					}}
				></p>

				<div className="speaker-event">
					{modalData?.session_speakers?.length > 0 && (
						<>
							<Typography variant="h6" className="gentle" gutterBottom>
								Speakers
							</Typography>
							<div className="speaker-grid">
								{modalData?.session_speakers?.length > 0 && (
									<>
										{modalData?.session_speakers[0].name ? (
											<>
												{modalData?.session_speakers?.map(
													(speaker: any, index) => (
														<div className="speaker-avatar" key={index}>
															<Avatar
																src={speaker?.image_url}
																alt={speaker?.name}
																sx={{ width: 40, height: 40 }}
																onClick={() => {
																	setOpenDrawer(true);
																	getSessionDetailsOfIndividual(
																		eventDetails.event_id,
																		speaker
																	);
																}}
															/>
															<>
																<CustomDrawer
																	onOpen={() => {
																		setOpenDrawer(true);
																	}}
																	open={openDrawer}
																	showModalAction={true}
																	component={
																		loading ? (
																			<CustomLoader />
																		) : (
																			<>
																				<SpeakerDetailsComp
																					speakerDetails={
																						speakerDetails
																					}
																					speakers={
																						speakers
																					}
																					setIsSessionDrawerOpen={
																						setIsSessionDrawerOpen
																					}
																					setModalOpenDesktop={
																						setModalOpenDesktop
																					}
																					eventDetails={
																						eventDetails
																					}
																					setModalData={
																						setModalData
																					}
																				/>
																			</>
																		)
																	}
																	onClose={() => {
																		setOpenDrawer(false);
																		setSpeakerDetails(null);
																	}}
																/>
															</>

															<p className="eventdraw-name">
																{speaker?.name}
															</p>
														</div>
													)
												)}
											</>
										) : (
											<>
												{modalData?.session_speakers
													?.map((data: object) => {
														const _speaker = speakers?.filter(
															each => each?.speaker_id === data
														)[0];
														data = { ..._speaker };
														return data;
													})
													.map((speaker, index) => (
														<div className="speaker-avatar" key={index}>
															<Avatar
																src={speaker?.image_url}
																alt={speaker?.name}
																sx={{ width: 40, height: 40 }}
															/>
															<p className="eventdraw-name">
																{speaker?.name}
															</p>
															{/* <Typography variant="caption" className="eventdraw-dec">{speaker.session_description}</Typography> */}
														</div>
													))}
											</>
										)}
									</>
								)}
							</div>
						</>
					)}

					{modalData?.tags?.length > 0 && (
						<>
							<Typography variant="h6" className="gentle">
								Tags
							</Typography>

							<div className="tags-container">
								{modalData?.tags?.map((tag, index) => (
									<span key={index} className="tag-chip">
										{tag?.name}
									</span>
								))}
							</div>
						</>
					)}
				</div>
			</div>
		</SwipeableDrawer>
	);
};

export default EventDrawer;
